.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 6px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  /* border: 1px solid #aaa; */
  border: 0;
  background: transparent;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 31px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  z-index: 2;
}

.react-autosuggest--relative
  .react-autosuggest__container--open
  .react-autosuggest__suggestions-container {
  position: relative;
  top: 0;
  border-left: none;
  border-right: none;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
  /* blue2Highlight */
  background-color: rgba(0, 169, 164, 0.1);
}

/* CUSTOM STYLES */

.react-autosuggest__suggestions-container {
  max-height: 310px;
  overflow: auto;
}
