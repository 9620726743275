html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: #5a4a42;
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
#__next {
  display: flex;
  flex: auto;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  opacity: 0.6;
}

button {
  padding: 0;
}

textarea {
  box-sizing: border-box;
  resize: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

td,
th {
  border: 1px solid #ddd;
  padding: 0 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

input,
select {
  /* min-height: 2rem; */
  font-size: 1rem;
  font-family: sans-serif;
}

select {
  background: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.1),
    0px 1px 8px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px 0px rgba(0, 0, 0, 0.01);
}

.mla {
  margin-left: auto;
}
.mra {
  margin-right: auto;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.material-icons {
  font-size: inherit;
  color: inherit;
}

*:focus {
  outline: 2px solid rgba(0, 169, 164, 0.6);
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

/* Things that should be in Tachyons */
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
button:disabled:hover {
  opacity: 0.5;
}
