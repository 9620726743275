/* Heading reset */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.33em 0;
  font-weight: normal;
}
h1 {
  font-weight: bold;
}
/* Button reset */
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  cursor: pointer;
}

select {
  box-shadow: none;
  border-radius: 0;
  background-clip: padding-box;
  border: 1px solid #aaa;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  background-clip: padding-box;
  border: 1px solid #aaa;
}
input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: textfield;
  background-color: -internal-light-dark-color(white, black);
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 0px;
}
textarea,
input[type="“text”"] {
  -webkit-appearance: none;
}
button:disabled {
  cursor: not-allowed;
}
